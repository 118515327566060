<template>

  <a-spin class="loadingClass" v-if="dataValue.saveing === true" tip="正在保存模板 ..."></a-spin>

  <div v-if="dataValue.saveing === false">
    <a-input placeholder="模板标题" v-model:value="dataValue.addInput.tempLabel"/>
    <a-textarea class="tInfo"
                v-model:value="dataValue.addInput.tempInfo"
                placeholder="模板内容"
                :auto-size="{ minRows: 10, maxRows: 10 }"
    />
    <view class="bs">
      <view class="bs1">
        <a-input class="v1" placeholder="测试手机号" :allowClear="true" :maxlength="11" v-model:value="dataValue.testMobile"/>
        <a-button class="v2" @click="allHttp.testSMS">测试</a-button>
      </view>

      <a-button class="bs2" type="primary" @click="allHttp.addSMS">{{ dataValue.buttonTitle }}</a-button>
    </view>
    <view class="ts">
      1、模板内容里面的换行符将会自动删除。<br/>
      2、可使用变量：${company}，${user}，${model}。<br/>
      3、长期使用的模板，需要通过平台备案，未备案的模板发送会有延迟。
    </view>
  </div>
</template>

<script>
import {reactive} from "vue";
import {message} from "ant-design-vue";
import httpRequest from "@/utils/axios";

export default {
  name : "template_sms_add",
  emits : [ 'goClose' ],
  props : [ 'row' ],
  setup(props, { emit }) {

    let dataValue = reactive({

      saveing : false,
      buttonTitle : "",

      testMobile : "",

      addInput : {
        tempId : 0,
        typeId : 1,
        tempLabel : "",
        tempTitle : "",
        tempInfo : ""
      }

    });

    if (props.row === null) {
      dataValue.buttonTitle = "添加模板";
      dataValue.addInput.tempId = 0;
    } else {
      dataValue.buttonTitle = "修改模板";
      dataValue.addInput.tempId = props.row['tempId'];
      dataValue.addInput.tempLabel = props.row['tempLabel'];
      dataValue.addInput.tempInfo = props.row['tempInfo'];
    }


    let allHttp = {


      testSMS : () => {
        if (dataValue.testMobile === "") {
          message.error("请输入测试手机号");
        }

        let sendOneInput = {
          mobile : dataValue.testMobile,
          info : dataValue.addInput.tempInfo
              .replace( /\${company}/g, '闲芯公司')
              .replace( /\${user}/g, '张先生')
              .replace( /\${model}/g, 'AD7874anz')
        }

        httpRequest.post('/admin/ic7.sms/send.one', sendOneInput)
            .then((res) => {
              if (res.code !== 200) {
                message.error(res.message);
              } else {
                message.info('发送成功！');
              }
            })

      },

      addSMS : () => {

        if (dataValue.addInput.tempLabel === "") {
          message.error("请输入标题");
        }

        if (dataValue.addInput.tempInfo === "") {
          message.error("请输入模板内容");
        }

        dataValue.saveing = true;

        dataValue.addInput.tempTitle = dataValue.addInput.tempLabel; // 后台接口需要判断是否为空

        httpRequest.post('/admin/set/ic7.template/add', dataValue.addInput)
            .then((res) => {
              if (res.code !== 200) {
                message.error(res.message);
              } else {
                message.info('添加成功！');
                emit('goClose');
              }
            })
      }

    }

    return { dataValue, allHttp }
  }
}
</script>
f
<style scoped>
.tInfo { margin: 20px 0 20px 0; }
.ts { line-height: 30px; color: saddlebrown; clear: both; }
.bs { width: 100%; float: left;margin-bottom: 30px; }
.bs .bs1 {float: left}
.bs .bs1 .v1 {float: left; width: 150px; margin-right: 10px;}
.bs .bs1 .v2 {float: left}
.bs .bs2 {float: right;}
.loadingClass { width: 100%; text-align: center; padding-top: 100px; }
</style>