<template>
  <div class="noRow" v-if="dataValue.smsList.list.length === 0">还未添加任何模板</div>
  <div class="youRow" v-if="dataValue.smsList.list.length > 0">
    <table class="ahuiTable">
      <tr>
        <th class="min">模板标题</th>
        <th>模板内容</th>
        <th class="min">创建时间</th>
        <th class="min">修改</th>
        <th class="min">删除</th>
      </tr>
      <tr v-for="aRow in dataValue.smsList.list">
        <td class="min" style="text-align: left;">{{ aRow['tempLabel'] }}</td>
        <td>{{ aRow['tempInfo'] }}</td>
        <td class="min">{{ aRow['createTime'] }}</td>
        <td class="min">
            <a @click="allEvent.smsAdd.modify(aRow)">修改</a>
        </td>
        <td class="min">
          <a-popconfirm title="请确认您要删除当前记录？" ok-text="是" cancel-text="否" @confirm="allHttp.smsDel(aRow)">
            <a>删除</a>
          </a-popconfirm>
        </td>
      </tr>
    </table>
  </div>
  <div style="text-align:center;">
    <a-button type="primary" @click="allEvent.smsAdd.openWindow">添加一个新的短信模板</a-button>
  </div>


  <a-drawer
      :title="dataValue.addSMS.title"
      width="550"
      :visible="dataValue.addSMS.show"
      :maskClosable="false"
      :destroyOnClose="true"
      placement="right"
      @close="allEvent.smsAdd.closeWindow"
  >

    <smsAdd @goClose="allEvent.smsAdd.closeWindow2" :row="dataValue.addSMS.row" />
  </a-drawer>
</template>

<script>
import {reactive} from "vue";
import httpRequest from "@/utils/axios";
import {message} from "ant-design-vue";
import smsAdd from "@/view/set/template_sms_add"

export default {
  name : "template_sms",
  emits : [ 'setMenuKey' ],
  components : { smsAdd },
  setup(props, { emit }) {
    emit('setMenuKey', 'set.qxmbsz.sms');

    let dataValue = reactive({
      addSMS : {
        title : "",
        show : false,
        row : null
      },
      smsList : {
        rowCount : 0,
        list : []
      }

    })

    let allHttp = {

      smsList : () => {

        httpRequest.post('/admin/set/ic7.template/list', { "typeId" : 1 })
            .then((res) => {
              if (res.code !== 200) {
                message.error(res.message);
              } else {
                dataValue.smsList.rowCount = res.data['rowCount'];
                dataValue.smsList.list = res.data['list'];
              }
            })

      },

      smsDel : (row) => {

        httpRequest.post('/admin/set/ic7.template/del', { "typeId" : 1, "tempId" : row['tempId'] })
            .then((res) => {
              if (res.code !== 200) {
                message.error(res.message);
              } else {
                allHttp.smsList();
                message.info('删除成功！')
              }
            })
      },

      init() {
        allHttp.smsList();
      }
    }

    allHttp.init();


    let allEvent = {

      smsAdd : {
        modify : (row) => {
          dataValue.addSMS.show = true;
          dataValue.addSMS.title = "修改短信模板"
          dataValue.addSMS.row = row;
        },

        openWindow : () => {
          dataValue.addSMS.title = "添加一个新的模板"
          dataValue.addSMS.show = true;
          dataValue.addSMS.row = null;
        },

        closeWindow : () => {
          dataValue.addSMS.show = false;
        },

        closeWindow2 :()=>{
          allHttp.smsList();
          dataValue.addSMS.show = false;
        }
      }

    }

    return {
      dataValue,
      allHttp,
      allEvent
    }
  }
}
</script>

<style scoped>
.noRow { margin: 30px; text-align: center; }
.youRow { padding: 15px; }
</style>